<template>
    <el-table v-if="vehicleTableData"
              :data.sync="vehicleTableData"
              stripe
              :empty-text="emptyText"
              :id="'vehicleTable'+ key"
              class="vehicle-table-content"
              :header-cell-style="headerStyle"
              :cell-style="rowStyle"
              @selection-change="handleSelectionChange"
              @row-click="handleRowClick"
              @sort-change="handleSortChange"
              :default-sort="currentSort">
        <el-table-column
                type="selection"
                min-width="40">
        </el-table-column>
        <el-table-column
                :label="$t('vehicle.vehicleName')"
                prop="Name"
                min-width="115"
                sortable="custom"
        >
            <template slot-scope="scope">
                <!--<el-link @click="viewVehicle(scope.row)">-->
                <div class="text-overflow-ellipsis" :title="scope.row.Name">{{scope.row.Name}}</div>
                <!--</el-link>-->
            </template>
        </el-table-column>
        <el-table-column
                :label="$t('vehicle.plateNumber')"
                prop="Id"
                sortable="custom"
                min-width="115">
            <template slot-scope="scope">
                <div class="text-overflow-ellipsis" :title="scope.row.Id">{{scope.row.Id}}</div>
            </template>
        </el-table-column>
        <el-table-column
                :label="$t('vehicle.vehicleModel')"
                prop="Model"
                sortable="custom"
                min-width="100">
            <template slot-scope="scope">
                <div class="text-overflow-ellipsis" :title="scope.row.Model">{{scope.row.Model}}</div>
            </template>
        </el-table-column>

        <el-table-column
                align="center"
                :label="$t('vehicle.permittedDrivers')"
                sortable="custom"
                prop="PermittedDrivers"
                min-width="125">
        </el-table-column>
        <el-table-column
                min-width="140"
                align="center"
                sortable="custom">
            <template slot="header" slot-scope="scope">
                <div class="expand-row">
                    <span style="display: block">{{$t('vehicle.registered')}}</span>
                    <span style="display: block;font-size: 12px;margin-top: 5px">{{"("+$t('vehicle.deviceAndFirmware')+")"}}</span>
                </div>
            </template>
            <template slot-scope="scope">
                <span v-if="!scope.row.Registered"> <i class="el-icon-close state-no-check"></i></span>
                <span v-else> {{'Mobile360 '+scope.row.MainModel}} {{scope.row.FirmwareVersion}}</span>

            </template>
        </el-table-column>
        <el-table-column
                v-if="isShowTrialTime()"
                align="center"
                sortable="custom"
                prop="TTL"
                min-width="140">
            <template slot="header" slot-scope="scope">
                <div class="expand-row">
                    <span style="display: block">{{$t('vehicle.trialTimeDays')}}</span>
                    <span style="display: block;font-size: 12px;margin-top: 5px">{{"("+$t('vehicle.days')+")"}}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
                v-if="isShowRenewDate()"
                align="center"
                sortable="custom"
                prop="TTL"
                min-width="140">
            <template slot="header" slot-scope="scope">
                <div class="expand-row">
                    <span style="display: block">{{getRenewDateTitle()}}</span>
                </div>
            </template>
             <template slot-scope="scope">
                 {{ parseFloat(scope.row.TTL) > 0 ? getRenewDateStr(scope.row.TTL) :  $t('globalText.none') }}
             </template>
        </el-table-column>
        <el-table-column
                v-if="isShowKVSTime()"
                align="center"
                sortable="custom"
                prop="KVSTTL"
                min-width="145">
            <template slot="header" slot-scope="scope">
                <div class="expand-row">
                    <span style="display: block">{{$t('vehicle.KVSTimeHMS')}}</span>
                    <span style="display: block;font-size: 12px;margin-top: 5px">{{"("+$t('vehicle.hms')+")"}}</span>
                </div>
            </template>
        </el-table-column>
        <!--        <el-table-column-->
        <!--                label="Description"-->
        <!--        >-->
        <!--        </el-table-column>-->
        <el-table-column align="center">
            <template slot-scope="scope">
                <span class="via-edit edit-button" @click="editVehicle(scope.row)"></span>
            </template>
        </el-table-column>

    </el-table>
    <!--<v-table :table-head="tableHead" :table-data="vehicleTableData"   @deleteRow="deleteVehicle" @editRow="editVehicle">-->
    <!--<template slot="Name" slot-scope="scope">-->
    <!--<el-link  @click="viewVehicle(scope.row)"><span-->
    <!--style="color: #0066cc ">{{scope.row.Name}}</span>-->
    <!--</el-link>-->
    <!--</template>-->
    <!--</v-table>-->
</template>

<script>
    import {CustomerConfigVehicleList} from '@/services/customer/CustomerConfig'
    import {formatDays, formatSeconds} from "@/assets/js/Util";
    import sort from "@/assets/js/Sort";
    import bus from '../../../utils/bus'

    export default {
        name: "Vehicle",
        components: {},
        props: {
            vehicleList: {type: Array, default: null},
            color: {type: String, default: "#909090"},
            fleetId: {
                type: String, default: ""
            }
        },
        data() {
            return {
                vehicleTableData: [],
                columnLength: 8,
                key: this.fleetId,
                currentSort: {prop: 'Name', order: "ascending"},
                lastSortOrder: null,
                lastSortObj: null,
            }
        },
        computed: {
            emptyText() {
                if (this.key == "unassignedVehicle") {
                    return this.$t('fleet.noUnassignedVehicle')
                }
                return this.$t('fleet.noVehicleAssigned')
            },
            vehicleSearchKey() {
                return this.$store.getters.getVehicleSearchKey;
            }
        },
        watch: {
            vehicleList: function () {
                this.setVehicleTableData();
            },
            Id: function (val) {
                this.key = val;
            }
        },
        methods: {
            isShowKVSTime() {
                return CustomerConfigVehicleList.isShowKVSTime(true)
            },
            isShowTrialTime() {
                return CustomerConfigVehicleList.isShowTrialTime(true)
            },
            isShowRenewDate() {
                return CustomerConfigVehicleList.isShowRenewDate(false)
            },
            getRenewDateTitle() {
                return CustomerConfigVehicleList.getRenewDateTitle(this.$i18n.locale)
            },
            getRenewDateStr (ttl) {
                let timestame = Math.round(Date.now() / 1000) + parseFloat(ttl) * 100 * 36 * 24
                return this.$dateFormat.dateTime(timestame, 'YYYY-MM-DD')
            },
            handleSelectionChange(val) {
                this.$emit("selectChange", val)
            },
            handleRowClick(row,) {
                this.$router.push({name: "viewVehicle", query: {id: row.Id}}).catch();
            },
            headerStyle(data) {
                if (!this.color) {
                    this.color = "#4c4c4c";
                }
                var style = 'background:' + this.color + ';color:white;border:1px solid ' + this.color;
                return style;
            },
            rowStyle(data) {
                var style = 'border:none;';
                if (data.columnIndex == 0) {
                    style += 'border-left:1px solid ' + this.color + ';';
                }
                if (data.columnIndex == this.columnLength) {
                    style += 'border-right:1px solid ' + this.color + ';';
                }
                if (data.rowIndex == this.vehicleTableData.length - 1) {
                    style += 'border-bottom:1px solid ' + this.color + ';';
                }
                return style;
            },
            setVehicleTableData() {
                if (this.vehicleList) {
                    this.vehicleTableData = this.vehicleList.map(item => {
                        return {
                            Name: item.Name,
                            Id: item.Id,
                            Model: item.Model ? item.Model : "",
                            MainModel: item.Device ? item.Device.MainModel : "",
                            FirmwareVersion:item.Device ? this.getFirmwareVersion(item.Device.Firmware) : "",
                            Registered: item.DeviceId ? true : false,
                            PermittedDrivers: item.DriverIds ? item.DriverIds.length : 0,
                            TTL: item.Device && item.Device.TTL != undefined ? formatDays(item.Device.TTL) : "",
                            KVSTTL: item.Device && item.Device.KVSTTL != undefined ? formatSeconds(item.Device.KVSTTL) : "",
                        }
                    })
                    this.vehicleTableData = this.sortData(this.vehicleTableData, this.currentSort);
                }
            },
            //Firmware 字段有的带有字母v有的没有，统一处理
            getFirmwareVersion(data){
                if (data) {
                    let index = data.indexOf('v');
                    if (index !== -1) {
                        return data
                    }else {
                        return 'v' + data.slice(index + 1, data.length);
                    }
                }else {
                    return '';
                }

            },
            viewVehicle(data) {
                this.$router.push({name: "viewVehicle", query: {id: data.Id}}).catch();
            },
            editVehicle(data) {
                this.$router.push({name: "editVehicle", query: {id: data.Id}}).catch();

            },
            formatDays(value) {
                return formatDays(value);
            },
            formatSeconds(value) {
                return formatSeconds(value);
            },
            setEmptyTextStyle() {
                var emptytext = '#vehicleTable' + this.key + " .el-table__empty-block";
                $(emptytext).css("border", "1px solid " + this.color);
            },
            sortData(data, sorts) {
                function SortFunc(value1, value2) {
                    let compareKVSTTL = (a, b) => {
                        let padA = a.KVSTTL.padStart(20, '0'),
                            padB = b.KVSTTL.padStart(20, '0');
                        return sort.NameSort(padA, padB);
                    };
                    let compareTTL = (a, b) => {
                        let aValue = parseFloat(a.TTL),
                            bValue = parseFloat(b.TTL);
                        if (isNaN(aValue)) aValue = -1;
                        if (isNaN(bValue)) bValue = -1;
                        return aValue - bValue;
                    };
                    var compare = (a, b, prop) => {
                        switch (prop) {
                            case "TTL":
                                return compareTTL(a, b);
                            case "KVSTTL":
                                return compareKVSTTL(a, b);
                            case 'Name':
                            case "Id":
                            case "Model":
                                return sort.NameSort(a[prop], b[prop]);
                            // case "Registered":
                            //     return a[prop] ? (b[prop] ? 0 : -1) : (b[prop] ? 1 : 0);
                            default:
                                if (a[prop] === "") return -1;
                                if (b[prop] === "") return 1;
                                return a[prop] - b[prop];
                        }
                    };
                    let compareName = (value1, value2) => {
                        return compare(value1, value2, 'Name');
                    };
                    let sortValue = 0;
                    if (sorts.order === 'ascending') {
                        sortValue = compare(value1, value2, sorts.prop);
                    } else {
                        sortValue = compare(value2, value1, sorts.prop);
                    }
                    if (sortValue === 0)
                        sortValue = compareName(value1, value2);
                    return sortValue;
                }

                return data.sort(SortFunc);
            },
            handleSortChange(data) {
                if (this.lastSortObj && this.lastSortObj.prop !== data.prop) {
                    this.lastSortObj.column.order = null;
                    this.lastSortObj.order = null;
                }
                const ASCE = 'ascending', DESC = 'descending';
                if (data.order === null) {
                    if (this.lastSortOrder === null)
                        this.lastSortOrder = ASCE;
                    else
                        this.lastSortOrder = this.lastSortOrder === 'ascending' ? DESC : ASCE;
                    data.column.order = this.lastSortOrder;
                } else {
                    this.lastSortOrder = data.column.order;
                }

                if (data.order == null) {
                    this.currentSort.order === 'descending' ? data.order = 'ascending' : data.order = 'descending'
                    data.column.order = data.order
                }
                this.lastSortObj = data;

                this.currentSort = {
                    order: data.order,
                    prop: data.prop
                };
                this.vehicleTableData = this.sortData(this.vehicleTableData, data);


                this.rememberSort();
            },
            rememberSort() {
                var sortData = {
                    id: this.fleetId,
                    sort: this.currentSort
                }
                this.$store.commit("setVehiclesSort", sortData);
            },
            getSort() {
                var sort = this.$store.getters.getVehiclesSort(this.fleetId);
                if (sort)
                    this.currentSort = sort;
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.setEmptyTextStyle();
            });
        },
        created() {
            this.getSort();
            this.setVehicleTableData();
            bus.$on('unRegisterDevice', vehicleId => {
                if (this.$store.getters.getKeepAlivePage.length !== 0) {
                    if (!this.vehicleTableData) return;
                    this.vehicleTableData.forEach(item => {
                        if (item.Id === vehicleId) {
                            item.Registered = false;
                            item.FirmwareVersion = '';
                            item.KVSTTL = '';
                            item.MainModel = '';
                            item.TTL = '';
                        }
                    })
                }
            });
            bus.$on('deleteVehicle', vehicleId => {
                if (this.$store.getters.getKeepAlivePage.length !== 0){
                    if (!this.vehicleTableData) return;
                    for (let i = 0; i < this.vehicleTableData.length; i++) {
                        if (this.vehicleTableData[i].Id === vehicleId) {
                            this.vehicleTableData.splice(i, 1);
                            break;
                        }
                    }
                }

            });

        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

</style>

<style lang="stylus">
    @import "~@/assets/stylus/color.styl"

    .vehicle-table-content:before {
        display none;
        height 0
    }

    .vehicle-table-content .el-table__header-wrapper, .vehicle-table-content .el-table__empty-block {
        cursor default
    }

    .vehicle-table-content .el-table__body-wrapper tr {
        cursor pointer
    }

    .vehicle-table-content .el-table__empty-block {
        border: 1px solid $color_gray_third;
        border-top: none;
    }

    .vehicle-table-content .el-table__body tr.el-table__row--striped td {
        background: $color_gray_first;
    }

    .vehicle-table-content tr:hover > td {
        background-color: $color_blue_hover !important;

    }

    .vehicle-table-content th > .cell
        padding-left 0
        padding-right 0

    .vehicle-table-content .el-table-column--selection .cell
        padding-left 14px
        padding-right 14px

    >>> .el-table .caret-wrapper
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 5px;

    .expand-row
        line-height 15px!important
        vertical-align middle
    .fleet-table-content .vehicle-table-content th
        padding 3px 0
</style>
