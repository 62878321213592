<template>
    <div class="fleet-content">
        <v-table ref="fleetTable"
                 :table-head="fleetTableHead"
                 :table-data="fleetTableData"
                 :is-show-page="false"
                 :expand-row-keys="defaultExpandRowKey"
                 row-key="Id"
                 @deleteRow="deleteRows"
                 @editRow="editFleet"
                 @rowClick="handleRowClick"
                 @sortChange="handleSortChange"
                 @selectChange="handleSelectFleetChange"
                 :default-sort="defaultSort"
                 :selectable="checkSelectable"
                 :editable="checkEditable"
                 class="fleet-table-content">
            <template slot="Name" slot-scope="scope">
                <span class="via-vehicles via-icon "
                      :style="{color:scope.row.Color ?scope.row.Color :'#4c4c4c' }"></span>
                <span class="fleet-name text-overflow-ellipsis" :title="scope.row.Name">{{scope.row.Name}}</span>
            </template>
            <template slot="Expand" slot-scope="scope">
                <Vehicle :vehicle-list="scope.row.Vehicles" :color="scope.row.Color" :fleet-id="scope.row.Id" ref="vehicleList"
                         @selectChange="handleSelectVehicleChange"></Vehicle>
            </template>
        </v-table>
    </div>

</template>

<script>
    import Vehicle from "./VehicleTable"
    import vTable from '../../../components/common/Table';
    import sort from "@/assets/js/Sort";
    import {concatUnique} from "@/assets/js/Util";
    import bus from "../../../utils/bus"
    import store from '../../../store'
    import ServerAPI from "../../../assets/js/ServerAPI";

    export default {
        name: "Fleet",
        components: {
            Vehicle,
            vTable
        },
        data() {
            return {
                sortType: {prop: 'Name', order: 'ascending'},
                isShowAddVehicle: false,
                unAssignedVehicleId: 'unassignedVehicle',
                fleetTableHead: [
                    {
                        name: this.$t('vehicle.fleetName'),
                        prop: "Name",
                        sortable: 'custom',
                        minWidth: "160"
                    },
                    {
                        name: this.$t('vehicle.totalVehicles'),
                        prop: "VehiclesNumber",
                        minWidth: "150",
                        sortable: 'true',
                        align: "center",
                    },
                    {
                        name: this.$t('vehicle.totalDrivers'),
                        prop: "DriversNumber",
                        minWidth: "150",
                        sortable: 'true',
                        align: "center",

                    },
                    {
                        name: this.$t('vehicle.description'),
                        prop: "Descript",
                        minWidth: "300",

                    },
                    {
                        name: "",
                        prop: "Expand",
                        minWidth: "1",
                        type: "expand"

                    },

                ],
                fleetTableData: null,
                fleetData: [],
                selectFleets: [],
                selectVehicles: [],
                isDeleting: false,
                expendKeys: [],//查询结果展开key
                routeExpandKey: [], // 通过路由决定展开的table的id,
                currentExpandKey: null,//当前展开key
                sortMethods: [
                    {
                        prop: "Name",
                        sort: sort.NameSort

                    }
                ]
            };
        },
        computed: {
            fleetList: function () {
                return this.$store.state.fleetData.fleetList;
            },
            defaultExpandRowKey: function () {
                let defaultKey = this.expendKeys;
                return this.fleetTableData && this.fleetTableData.length > 0 ? defaultKey : null;
            },
            defaultSort: function () {
                if (this.context && this.context.fleetSort)
                    return this.context.fleetSort;
                return {prop: 'Name', order: 'ascending'}
            },
            vehicleSearchKey() {
                return this.$store.getters.getVehicleSearchKey;
            },
            keepAliveCom(){
                return store.getters.getKeepAlivePage;
            }

        },
        watch: {
            keepAliveCom(val){
                if (!val || val.length === 0){
                    this.fleetTableData = null;
                    this.fleetData = [];
                }
            },
            fleetList(val) {
                if (val)
                    this.fleetData = val.Sub ? val.Sub : [];
            },
            unAssignedVelList(val) {
                if (val) {
                    //fixed by colin, delete if fleetData.length > 0
                    let unAssignedItem = {
                        Id: "unassignedVehicle",
                        Name: this.$t('fleet.unassignedVehicles'),
                        Color: "#909090",
                        Vehicles: val
                    };
                    this.fleetData.push(unAssignedItem);
                    this.fleetTableData = this.getFleetTableData(this.fleetData);
                }
            },
            vehicleSearchKey(value) {
                let val = value.trim();
                var expendKey = [];
                var fleetData = JSON.parse(JSON.stringify(this.fleetData));
                if (val) {
                    fleetData = fleetData.map((item) => {
                        if (item.Vehicles) {
                            item.Vehicles = item.Vehicles.filter((subItem) => {
                                function checkFind(prop) {
                                    if (prop && prop.toLowerCase().indexOf(val.toLowerCase()) >= 0)
                                        return true;
                                    return false;
                                }

                                if (checkFind(subItem.Id)) {
                                    return true;
                                }
                                else if (checkFind(subItem.Name)) {
                                    return true;
                                }
                                else
                                    return checkFind(subItem.Model);
                            });
                            if (item.Vehicles.length > 0)
                                expendKey.push(item.Id);
                        }
                        return item;
                    })
                    this.expendKeys = expendKey;
                } else {
                    this.expendKeys = [this.currentExpandKey];
                }

                this.fleetTableData = this.getFleetTableData(fleetData);
            }
        },
        methods: {
            getFleetTableData(data) {
                var fleetTableData = [];
                if (data != null) {
                    for (var i = 0; i < data.length; i++) {
                        var item = {
                            Id: data[i].Id,
                            Descript: data[i].Descript,
                            Name: data[i].Name,
                            Color: data[i].Color,
                            CompanyName: data[i].Company ? data[i].Company.Name : '',
                            VehiclesNumber: data[i].Vehicles ? data[i].Vehicles.length : 0,
                            FleetManagers: data[i].FleetManagers ? data[i].FleetManagers : [],
                            Vehicles: data[i].Vehicles ? data[i].Vehicles : [],
                            DriversNumber: data[i].Vehicles ? this.getDriversNumber(data[i].Vehicles) : 0
                        }
                        fleetTableData.push(item);
                    }
                    return this.SortFleet(fleetTableData, this.$refs.fleetTable.getCurrentSort());
                }
                return [];

            },
            getDriversNumber(vehicles) {
                var uniqueDriverIds = [];
                for (var i = 0; i < vehicles.length; i++) {
                    if (vehicles[i].DriverIds)
                        uniqueDriverIds = concatUnique(uniqueDriverIds, (vehicles[i].DriverIds));
                }
                return uniqueDriverIds.length;
            },
            checkSelectable(row, rowIndex) {
                if (row.Id == this.unAssignedVehicleId) {
                    return false;
                }
                return true;
            },
            checkEditable(row) {
                if (row.Id == this.unAssignedVehicleId) {
                    return false;
                }
                return true;
            },
            handleSelectFleetChange(val) {
                this.selectFleets = val;
                this.$refs['fleetTable'].multipleSelection = this.selectVehicles.concat(val);
            },
            handleSelectVehicleChange(val) {
                this.selectVehicles = val;
                this.$refs['fleetTable'].multipleSelection = this.selectFleets.concat(val);
            },
            handleRowClick(row) {
                if (this.vehicleSearchKey) return;
                let $table = this.$refs.fleetTable;
                if (this.fleetTableData)
                    this.fleetTableData.map((item) => {
                        if (item.Id != this.unAssignedVehicleId) {
                            if (row.Id != item.Id) {
                                $table.toggleRowExpansion(item, false)
                            }
                        }
                    });
                $table.toggleRowExpansion(row);
                this.currentExpandKey = row.Id;

            },
            editFleet(data) {
                this.$router.push({name: "editFleet", query: {id: data.Id}}).catch();
            },
            deleteRows(delIds, callback) {
                var delFleetIds = this.selectFleets.map((item) => {
                    return item.Id
                });
                if (delFleetIds.length > 0) {
                    var delFleetDone = null;
                    if (this.selectVehicles.length > 0) {
                        delFleetDone = () => {
                            this.deleteVehicles(callback);
                        }
                    }
                    this.deleteFleets(delFleetIds, delFleetDone, callback);
                } else if (this.selectVehicles.length > 0) {
                    this.deleteVehicles(callback);
                }
            },
            deleteDone(callback) {
                this.refreshList(() => {
                    this.$message(
                        {
                            message: this.$t('globalText.deletedSuccessfully'),
                            type: 'success'
                        }
                    );
                    if (callback) {
                        callback();
                    }
                });

            },
            deleteFleets(delIds, delvechiles, callback) {
                this.$serverApi.fleet.deleteFleet(delIds, () => {
                    if (delvechiles) {
                        delvechiles();
                    } else {
                        this.deleteDone(callback);
                    }
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            },
            deleteVehicles(callback) {
                var delIds = this.selectVehicles.map((item) => {
                    return item.Id
                });
                this.$serverApi.vehicles.deleteVehicle(delIds, () => {
                    this.deleteDone(callback);
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            },
            refreshList(callback) {
                this.$store.dispatch("getFleetList").then(() => {
                    if (this.routeExpandKey) {
                        let orderNumber = 1;
                        let tableData = this.fleetData;
                        tableData && tableData.forEach((item, index) => {
                            if (item.Id === this.routeExpandKey) orderNumber = index;
                        });
                        this.$nextTick(() => {
                            if (orderNumber > -1)
                                this.$parent.scrollTo(orderNumber * 35);
                        });
                    }
                    ServerAPI.vehicles.getUnassignedList(data =>{
                        if (data) {
                            //fixed by colin, delete if fleetData.length > 0
                            let unAssignedItem = {
                                Id: "unassignedVehicle",
                                Name: this.$t('fleet.unassignedVehicles'),
                                Color: "#909090",
                                Vehicles: data
                            };
                            this.fleetData.push(unAssignedItem);
                            this.fleetTableData = this.getFleetTableData(this.fleetData);
                        }
                        this.$store.commit("updateUnassignedVehicleList", data);
                        if (callback)
                            callback();
                    })


                });

            },
            SortFleet(data, sortData) {
                function SortFunc(value1, value2) {
                    var compare = function (a, b, prop) {
                        return prop === "Name" ? sort.NameSort(a[prop], b[prop]) : a[prop] - b[prop];
                    };
                    let sortNum = 0;
                    if (sortData.order === 'ascending')
                        sortNum = compare(value1, value2, sortData.prop);
                    else {
                        sortNum = compare(value2, value1, sortData.prop);
                    }
                    if (sortNum === 0 && sortData.prop !== 'Name')
                        sortNum = sort.NameSort(value1.Name, value2.Name);
                    return sortNum;
                }

                if (data) {
                    var sortFleetData = [].concat(data.slice(0, data.length - 1).sort(SortFunc));
                    return sortFleetData.concat(data.slice(data.length - 1));
                }
            },
            handleSortChange(data) {
                this.fleetTableData = this.SortFleet(this.fleetTableData, data);
                this.rememberContext();
            },
            rememberContext() {
                var sort = this.$refs.fleetTable.getCurrentSort();
                this.$store.commit("setFleetSort", sort);
            },
            getContext() {
                var ctx = this.$store.state.fleetData.fleetListCtx;
                if (ctx) {
                    this.context = ctx;
                }
            }

        },
        beforeRouteLeave:((to, from, next) => {
            if (to.path !== '/management/vehicle/view-vehicle') {
                store.commit('setVehicleSearchKey', '');
                bus.$emit("clearFleetSearchKey");
                store.commit('setKeepAlivePage',null)
            }else {
                store.commit('setKeepAlivePage','Fleet')
            }
            next();
        }),
        activated(){
            if (store.getters.getKeepAlivePage.length === 0) {
                this.fleetTableData = null;
                this.fleetData = [];
                if (this.$route.query.fleetId) {
                    this.routeExpandKey = this.$route.query.fleetId;
                    this.expendKeys = [this.routeExpandKey];
                    this.currentExpandKey = this.routeExpandKey;
                } else {
                    this.expendKeys = [this.unAssignedVehicleId];
                    this.currentExpandKey = this.unAssignedVehicleId;
                }
                this.refreshList();
                this.getContext();
            }
        }
    }

</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .fleet-content {
        padding: 20px 20px 20px 30px;
        height: 100%;
    }

    .bottom-area {
        margin-top: 20px;
    }

    .fleet-table-content {
        color: $color_black_first;
        /*width: 100%;*/
    }

    .fleet-table-content::before {
        height: 0;
    }

    .via-icon {
        font-size: 20px;
        padding-right: 10px;
        vertical-align middle
    }

    .fleet-name {
        vertical-align middle
    }

    >>> .el-table__expanded-cell[class*=cell] {
        padding 8px 50px;
    }


</style>
<style lang="stylus">
    @import "~@/assets/stylus/color.styl";
    .el-table__expand-icon {
        height: 0 !important;
    }

    .fleet-table-content .el-table__body-wrapper:not(.el-table__row) {
        cursor pointer
    }

    .fleet-table-content td, .fleet-table-content th.is-leaf {
        border-bottom: 1px solid $color_gray_third;
    }

    .fleet-table-content td, .fleet-table-content th {
        padding 6px 0;
    }

    .fleet-table-content td:first-child {
        border-left: 1px solid $color_gray_third;
    }

    .fleet-table-content td:last-child {
        border-right: 1px solid $color_gray_third;
    }

    .fleet-table-content th {
        color: white;
        background-color: $color_blue_second;

    }

    .fleet-table-content .el-table__body {
        width 100% !important;
    }

    .fleet-table-content .el-table__body tr.el-table__row--striped td {
        background: $color_gray_first;
    }

    .fleet-table-content tr:hover > td {
        background-color: $color_blue_hover !important;

    }

    .fleet-table-content .el-table__empty-block {
        border: 1px solid $color_gray_third;
        border-top: none;
    }

    .fleet-table-content .el-checkbox__inner {
        border-color: $color_black_third;
    }

    .fleet-table-content .el-table__expanded-cell[class*=cell] {
        padding: 8px 40px;
    }

</style>
